// import React, { useEffect, useState } from "react";
// import { Button, Form, FormControl } from "react-bootstrap";
// import { useRef } from "react";
// import { FaFacebookF } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
// import { FaInstagram,FaWhatsapp } from "react-icons/fa";
// import emailjs from "emailjs-com";
// import $ from "jquery";
// import "./contact.css";

// const Contact = () => {
//   const [form,setForm] = useState({
//     name:"",
//     email:"",
//     message:""
//   });

//   // const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     console.log(form);
//     // emailjs.sendForm(
//     //   "service_kde8cna",
//     //   "template_xbz4n23",
//     //   form.current,
//     //   "Iie2MuIASZY1-v8k4"
//     // );

//     // e.target.reset();
//   };
//   useEffect(() => {
//     // Example jQuery code
//     $(".full-landing-image").ripples({
//       resolution: 130,
//       perturbance: 0.005,
//     });
//   }, []);

//   const handleChange = (event)=>{
//     setForm(() => ({
//       ...form,
//       [event.target.name]: event.target.value
//   }));
//   }
//   return (
//     <section>
//       <div className="container bg_home ml-3">
//         <div className="row">
//           <div className="col-lg-6 col-sm-12 img_men ">
//             <div className="full-landing-image"></div>
//           </div>
//           <div className="col-lg-6 col-sm-12">
//             <div className="wid-titles mt-5">
//               <span>Get in Touch</span>
//             </div>

//             <Form className="mt-5 pt-2" ref={form} onSubmit={sendEmail}>
//               <Form.Group className="mb-4" controlId="name">
//                 <FormControl
//                   type="text"
//                   placeholder="Enter your name"
//                   name="name"
//                   className="text-black"
//                   value={form.name}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-4" controlId="name">
//                 <FormControl
//                   type="email"
//                   placeholder="Enter your Email"
//                   //   value={email}
//                   name="email"
//                   className="text-black"
//                   value={form.email}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group
//                 className="mb-4"
//                 controlId="exampleForm.ControlTextarea1"
//               >
//                 <Form.Control
//                   as="textarea"
//                   name="message"
//                   className="text-black"
//                   rows={3}
//                   value={form.message}
//                   onChange={handleChange}
//                   placeholder="Enter Your Message"
//                 />
//               </Form.Group>
//               <Button
//                 variant="primary"
//                 type="submit"
//                 style={{
//                   backgroundColor: "#000000",
//                   padding: "13px 45px",
//                   color: "#fff",
//                   border: "none",
//                 }}
//               >
//                 Send Message
//               </Button>
//             </Form>
//             <hr className="mb-2" />
//             <div className="wid-title">
//               <div className="social-icons">
//                 <div className="mt-lg-4 mb-lg-4 text-light icons">
//                   <a href="https://www.facebook.com/Talhaspeak?mibextid=ZbWKwL">
//                     <FaFacebookF />
//                   </a>
//                 </div>
//                 <br></br>
//                 <div className="text-light mt-lg-4 icons">
//                   <a href="https://x.com/Talha_Khanpak?s=09">
//                     <FaTwitter />
//                   </a>
//                 </div>
//                 <br>
//                 </br>
//                 <div className="mt-lg-4 mb-lg-4 text-light icons">
//                   <a href="https://www.linkedin.com/in/talha-khan-2a72b3166?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
//                     <FaLinkedinIn />
//                   </a>
//                 </div>
//                 <br>
//                 </br>
//                 <div className="text-light mt-lg-4 icons">
//                   <a href="https://www.instagram.com/eagletalented/">
//                     <FaInstagram />
//                   </a>
//                 </div>
//                 <br>
//                 </br>
//                 <div className="text-light mt-lg-4 icons">
//                   <a href="https://whatsapp.com/channel/0029VajVK70KrWR0NcqZ3A0t">
//                     <FaWhatsapp />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container">
//         <div className="row">
//           <span className="mt-2">
//             Copyright © 2023 | All Rights Reserved | Mr.Talha
//           </span>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Contact;

import React, { useEffect } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import $ from "jquery";
import "./contact.css";

const Contact = () => {
  useEffect(() => {
    // Example jQuery code
    $(".full-landing-image").ripples({
      resolution: 130,
      perturbance: 0.005,
    });
  }, []);

  return (
    <section>
      <div className="container bg_home ml-3">
        <div className="row">
          <div className="col-lg-6 col-sm-12 img_men ">
            <div className="full-landing-image"></div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="wid-titles mt-5">
              <span>Contact Me</span>
            </div>
            <div className="mt-5 pt-2">
              <p>
                If you'd like to get in touch, feel free to reach out via any of
                the social platforms below:
              </p>
            </div>
            <hr className="mb-2" />
            <div className="wid-title">
              <div className="social-icons">
                <button class="button-whatapp">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fff"
                      d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                    ></path>
                    <path
                      fill="#cfd8dc"
                      d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                    ></path>
                    <path
                      fill="#40c351"
                      fill-rule="evenodd"
                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://whatsapp.com/channel/0029VajVK70KrWR0NcqZ3A0t"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join My Whatapp Group
                  </a>
                </button>
                <button class="button-whatapp">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fff"
                      d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                    ></path>
                    <path
                      fill="#cfd8dc"
                      d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                    ></path>
                    <path
                      fill="#40c351"
                      fill-rule="evenodd"
                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://wa.me/+971562632078"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Me On Whatapp
                  </a>
                </button>
                <button class="button-facebook">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fff"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                    ></path>
                    <path
                      fill="#3F51B5"
                      d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://www.facebook.com/Talhaspeak?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Follow Me On Facebook
                  </a>
                </button>
                <button class="button-twitter">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fff"
                      d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://x.com/Talha_Khanpak?s=09"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Follow Me On Twitter
                  </a>
                </button>
                <button class="button-linkedin">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#0288D1"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                    ></path>
                    <path
                      fill="#FFF"
                      d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://www.linkedin.com/in/talha-khan-2a72b3166?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Follow Me On Linkedin
                  </a>
                </button>
                <button class="button-instagram">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 50 50"
                  >
                    <path
                      fill="white"
                      d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
                    ></path>
                  </svg>
                  <a
                    class="text"
                    href="https://www.instagram.com/eagletalented/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Follow Me On Instagram
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <span className="mt-2">
            Copyright © 2023 | All Rights Reserved | Mr.Talha
          </span>
        </div>
      </div>
    </section>
  );
};

export default Contact;
